body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #282c34;
  }  

.App {
    text-align: center;
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    color: white;
    font-family: Lato,Lato,LatoExtended,sans-serif;
  }
  
  .img-container {
    width: 620px;
    height: 360px;
    background: grey;
  }
  
  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .words-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-top: 80px;
    column-gap: 90px;
    row-gap: 20px;
    flex-wrap: wrap;
    
  }
  
  /* CSS */
  .button-33 {
    text-wrap: 'nowrap';
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
  //   cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 36px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  .wrong {
      color: white;
      background-color: red;
      box-shadow: rgba(187, 99, 99, 0.2) 0 -25px 18px -14px inset,rgba(187, 99, 99, 0.15) 0 1px 2px,rgba(187, 99, 99, 0.15) 0 2px 4px,rgba(187, 99, 99, 0.15) 0 4px 8px,rgba(187, 99, 99, 0.15) 0 8px 16px,rgba(187, 99, 99, 0.15) 0 16px 32px;
      pointer-events: none;
  }
  
  .button-33:hover {
    box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  //   transform: scale(1.05) rotate(-1deg);
  }
  
  .option-container {
      position: relative;
  }
  
  
  
  
  
  .cat {
    position: absolute;
    bottom: 0;
    margin: auto;
      height: 170px;
      width: 192.1px;
  }
  
  .ear {
      position: absolute;
      top: -30%;
      height: 60%;
      width: 25%;
      background: #fff;
      
      &::before,
      &::after {
          content: '';
          position: absolute;
          bottom: 24%;
          height: 10%;
          width: 5%;
          border-radius: 50%;
          background: #282c34;
      }
      
      &::after {
          transform-origin: 50% 100%;
      }
  }
  
  .ear--left {
      left: -7%;
      border-radius: 70% 30% 0% 0% / 100% 100% 0% 0%;
      transform: rotate(-15deg);
      
      &::before,
      &::after {
          right: 10%;
      }
      
      &::after {
          transform: rotate(-45deg);
      }
  }
  
  .ear--right {
      right: -7%;
      border-radius: 30% 70% 0% 0% / 100% 100% 0% 0%;
      transform: rotate(15deg);
      
      &::before,
      &::after {
          left: 10%;
      }
      
      &::after {
          transform: rotate(45deg);
      }
  }
  
  .face {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #282c34;
      border-radius: 50%;
  }
  
  .eye {
      position: absolute;
      top: 35%;
      height: 30%;
      width: 31%;
      background: #fff;
      border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
      
      &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 0;
          width: 100%;
          border-radius: 0 0 50% 50% / 0 0 40% 40%;
          background: #282c34;
          animation: blink 4s infinite ease-in;
      }
      
      @keyframes blink {
          0% { height: 0; }
          90% { height: 0; }
          92.5% { height: 100%; }
          95% { height: 0; }
          97.5% { height: 100%; }
          100% { height: 0; }
      }
      
      &::before {
          content: '';
          position: absolute;
          top: 60%;
          height: 10%;
          width: 15%;
          background: #fff;
          border-radius: 50%;
      }
  }
  
  .eye--left {
      left: 0;
      
      &::before {
          right: -5%;
      }
  }
  
  .eye--right {
      right: 0;
      
      &::before {
          left: -5%;
      }
  }
  
  .eye-pupil {
      position: absolute;
      top: 25%;
      height: 50%;
      width: 20%;
      background: #282c34;
      border-radius: 50%;
      animation: look-around 4s infinite;
      
      @keyframes look-around {
          0% { transform: translate(0) }
          5% { transform: translate(50%, -25%) }
          10% { transform: translate(50%, -25%) }
          15% { transform: translate(-100%, -25%) }
          20% { transform: translate(-100%, -25%) }
          25% { transform: translate(0, 0) }
          100% { transform: translate(0, 0) }
      }
      
      .eye--left & {
          right: 30%;
      }
      
      .eye--right & {
          left: 30%;
      }
      
  
      &::after {
          content: '';
          position: absolute;
          top: 30%;
          right: -5%;
          height: 20%;
          width: 35%;
          border-radius: 50%;
          background: #fff;
      }
  }
  
  .muzzle {
      position: absolute;
      top: 60%;
      left: 50%;
      height: 6%;
      width: 10%;
      background: #fff;
      transform: translateX(-50%);
      border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%;
  }
  
  